import { getFullDateTime, parseISO8601Duration } from '~/lib/utilities/datetime'
import { isISO8601Duration } from '~/lib/utilities/regex'
import {
  ColorConfig,
  LabelColor,
  LabelColorConfig,
  SocialLink,
} from '~/types/common'

export const convertSocialLink = (data: any): SocialLink => {
  return {
    key: data.Name,
    value: data.Value,
    name: data.Name?.replace('Url', ''),
  }
}

export const convertColorConfig = (data: any): ColorConfig => {
  return {
    textColor: data.TextColor,
    backgroundColor: data.BackgroundColor,
  }
}

export const convertLabelColor = (data: any): LabelColor => {
  return {
    name: data.Name?.toLowerCase(),
    ...convertColorConfig(data),
  }
}

export const convertLabelColorConfig = (data: any): LabelColorConfig => {
  const labels: LabelColorConfig['labels'] = {}
  data.Labels?.forEach((item: any) => {
    const converted = convertLabelColor(item)
    labels[converted.name] = converted
  })
  return {
    default: convertColorConfig(data.Default),
    labels,
  }
}

export const convertFullDateTime = (date: string, _time?: string) => {
  const time =
    _time && isISO8601Duration(_time)
      ? parseISO8601Duration(_time, 'hh:mm:ss')
      : _time
  return getFullDateTime(date, time)
}
